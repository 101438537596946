<template>
  <div class="wrapper">
    <img loading="lazy" alt="icon" id="img-top" src="/images/emprendedores/weunderstand.jpg" />
    <div class="background first">
      <div class="container">
        <h2>{{ $t('emprendedores.program_we_understand') }}</h2>
        <p>
          El CLUB Emprendedores hemos diseñado este programa para guiar
          a cada emprendedor de un modo práctico en el emprendimiento
          en un ecommerce.
        </p>
        <p>
          Conocemos por experiencia, que cuando decidimos emprender y
          crear una tienda ecommerce nos asaltan muchas dudas y no
          sabemos cuál es la mejor opción.
        </p>
        <p>
          En este programa trabajarás en tu proyecto y encontrarás
          respuestas a sobre qué pasos dar, herramientas a utilizar,
          podrás compartir tu experiencia con otros emprendedores.
        </p>
      </div>
    </div>
    <div class="second">
      <div class="container">
        <div>
          <h3 id="first">¿A QUIÉN VA DIRIGIDO?</h3>
          <div class="cont">
            <p>
                Si tienes un proyecto en marcha o una idea definida este
                programa es para ti.
            </p>
          </div>
        </div>
         <div>
          <h3>¿QUÉ TE PEDIMOS?</h3>
          <div class="cont">
            <p><strong>Compromiso, trabajo e ilusión.</strong></p>
            <p>
              Compromiso para trabajar en tu proyecto antes y después de
              cada taller, para que aprendas a medir los resultados, y tu
              proyecto crezca
            </p>
            <p>
              Te ayudaremos a encontrar respuestas, y te mostraremos técnicas
              y recursos para que avances en tu iniciativa.
            </p>
          </div>
        </div>
        <div class="workshops">
          <h3>LOS TALLERES DEL PROGRAMA</h3>
          <div class="cont">
            <b-row>
              <b-col cols="12" class="col-md-6">
                <h4>¿POR DÓNDE EMPIEZO?</h4>
                <div class="workshop">
                  <img loading="lazy" alt="icon" src="/images/emprendedores/workshop-blue.png" />
                  <ol>
                    <li>Check list de acciones a iniciales.</li>
                    <li>Definir el perfil cliente.</li>
                    <li>Cómo vender en internet.</li>
                  </ol>
                </div>
                <h4>¿CÓMO CONSIGO CLIENTES?</h4>
                <div class="workshop">
                  <img loading="lazy" alt="icon" src="/images/emprendedores/workshop-green.png" />
                  <ol>
                    <li>Facebook para llegar a mis clientes.</li>
                    <li>SEO Vs SEM. Publicitarme en Google.</li>
                    <li>Cómo usar e-mailmk para captar clientes.</li>
                  </ol>
                </div>
                </b-col>
                <b-col cols="12" class="col-md-6">
                <h4>¿QUÉ PLATAFORMA ELIJO Y CÓMO LA OPTIMIZO?</h4>
                <div class="workshop">
                  <img loading="lazy" alt="icon" src="/images/emprendedores/workshop-yellow.png" />
                  <ol>
                    <li>Qué plataforma elegir para vender.</li>
                    <li>SEO como arquitectura de mi proyecto.</li>
                    <li>Cómo gestiono mi ecommerce.</li>
                    <li>Analytic en el alma de mi negocio.</li>
                  </ol>
                </div>
                <h4>¿QUÉ HAGO PARA FIDELIZARLOS?</h4>
                <div class="workshop">
                  <img loading="lazy" alt="icon" src="/images/emprendedores/workshop-pink.png" />
                  <ol>
                    <li>Retener a nuestros usuarios.</li>
                    <li>Cómo utilizar el mk de resultados.</li>
                    <li>Inbound marketing (HubSpot).</li>
                  </ol>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="bottom">
          <div class="container">
            <b-row>
              <b-col cols="12" class="col-lg-3 colas">
                <div id="title" class="left">Emprendemos <div>CONTIGO</div></div>
              </b-col>
              <b-col cols="12" class="col-lg-5">
                <div>
                  <img loading="lazy" alt="icon" src="/images/emprendedores/grafico.png" />
                </div>
              </b-col>
              <b-col cols="12" class="col-lg-3 colas">
                <div class="right">
                  <div id="organisation">Organización</div>
                  <div class="time">
                    <font-awesome-icon :icon="['fas', 'clock']" class="icon-clock" />
                    <span>
                      <div>Todos los jueves de</div>
                    </span>
                  </div>
                  <div id="exact_time">13:30 a 14:00</div>
                  <p>
                    Los talleres son independientes. Puedes apuntarte al taller
                    que te interese.
                  </p>
                  <p>
                    Para más información e inscripciones
                    <strong><a href="mailto:estrategia@aicad.es">estrategia@aicad.es</a></strong>
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('emprendedores.program_we_understand'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.program_we_understand') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.program_we_understand') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    text-align: left;

    #img-top {
      width: 100%;
    }

    h3:not(#first) {
      margin: 30px 0;
    }

    .cont {
      margin: 30px 0 60px;
    }
  }

  .background.first {
    background: #f2f2f2;    
    padding: 50px 0;
  }

  .second {
      padding: 50px 0;
  }

  .workshops {
    ol {
      width: 100%;
    }

    h4 {
      font-weight: 400;
      font-size: 1.4rem;
      border-bottom: 3px solid #dedede;
      width: 95%;
      margin-bottom: 30px;
    }

    .workshop {
      display: flex;
      min-height: 130px;
      font-size: 0.9rem;

      img {
        height: 100px;
      }
    }
  }

  .bottom {
    .container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #f2f2f2;
      flex-wrap: wrap;
      padding: 20px 0;

      .colas {
        margin: auto;
      }

      img {
        width: 100%;
      }

      .right {
        font-size: 0.9rem;

        a {
         color: #009090;
        }

        #organisation {
          font-size: 1.5rem;
          font-weight: 700;
        }

        #exact_time {
          color: #009090;
          font-size: 1.5rem;
          font-weight: 700;
        }

        .icon-clock {
          color: #009090;
          font-size: 2rem;
          margin: auto 10px auto 0;
        }

        .time {
          display: flex;

          span {
            div {
              font-size: 1.2rem;
            }
          }
        }
      }

      #title {
        text-align: right;
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 300;

        div {
          font-weight: 600;
          font-size: 2.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .workshops {
      h4 {
        font-size: 1rem;
      }
    }

    .bottom {
      .container {
        justify-content: unset;

        .right,
        .left {
          width: 100%;
          text-align: center !important;
        }

        img {
          margin: 20px 0;
        }
      }
    }
  }
</style>